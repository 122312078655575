body {
  background-image: url(../public/background.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: black;
  /*text-shadow: 0px 0px 30px orange; */
  font-family: 'Raleway', sans-serif;
}

ul {
  list-style-type: none;
}

span {
background-color: white;
}



a:link , a:visited {
  color: black; 
}

a:hover {
  color: #A9A9A9; /* dark gray */
}

a:active {
  color: black;
}


.nav-btn {
  padding: .75em;
  font-size: 2em;
  font-weight: bold;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-container {
  border: 1px solid;
}

.inline-block {
  display: inline-block;
}

.team-member-well {
  border: 3px dashed green;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: white;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding-top: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: black;
  text-align: center;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.home-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
